<template>
  <div
    class="d-flex align-center"
    style="height: 100%">
    <div
      v-if="!editMode && !isExpandedMode"
      class="url-wrapper d-flex justify-center align-center"
      style="height:100%">
      <a
        v-for="(url, index) in value"
        :key="index"
        :href="linkToOpening(url)"
        target="_blank">
        {{ url }}
        <br>
      </a>
    </div>
    <template
      v-else>
      <div
        class="col-12 px-0"
        :class="[
          { 'editing-container' : !isExpandedMode },
          { 'py-0' : isExpandedMode }
        ]">
        <div
          class="d-flex flex-row align-center"
          :style="{ height: '100%', position: isExpandedMode ? 'relative' : ''}">
          <v-textarea
            ref="textareaRef"
            v-model="urlObj.newValue"
            class="border-outline sourcery__filled-grey"
            :class="{ 'textarea-scraper-padding' : isExpandedMode }"
            :auto-grow="isExpandedMode"
            :autofocus="!isExpandedMode"
            :disabled="!editMode"
            :rows="isExpandedMode ? 1 : 6"
            :hint="hintMessage"
            :hide-details="!urlErrorMessage.length && (!hintMessage || !isFocus)"
            :clearable="!isExpandedMode"
            :error-messages="urlErrorMessage"
            filled
            @focus="isFocus = true"
            @blur="update" />
          <div
            v-if="showScrapeButton"
            :class="['scraper', isScraperDisabled ? 'scraper-disabled' : 'scraper-enabled']">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <img
                  height="24"
                  width="24"
                  src="@/assets/icons/scraper-icon.svg"
                  v-bind="attrs"
                  v-on="on"
                  @click="scrapeUrlForData">
              </template>
              <span>{{ isScrapingProductData ? 'Importing...' : ( isScraperDisabled ? 'Enter valid Link to Import' : 'Import this Product') }}</span>
            </v-tooltip>
          </div>
        </div>
        <div
          v-if="showLinkOpenInNewTab"
          class="col-12 pl-2 pt-0 pb-0 pr-0 d-flex justify-start">
          <v-menu
            :nudge-bottom="22"
            bottom>
            <template #activator="{ on }">
              <a
                target="_blank"
                class="pointer mt-2"
                :href="null"
                v-on="on">
                Open link(s) in new tab(s)
              </a>
            </template>
            <v-list>
              <a
                v-for="(url, index) in value"
                :key="index"
                class="px-2 overflow-link"
                :style="{'max-width': textareaExpandedWidth && `${textareaExpandedWidth - 14}px`}"
                :href="linkToOpening(url)"
                target="_blank">
                {{ url }}
                <br>
              </a>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import EditModeChildComponent from '@/mixins/EditModeChildComponent';
import prependHttp from 'prepend-http';
import {
  mapGetters,
  mapState,
} from 'vuex';
import { waitTimeout } from '@/utils';
import {
  EventBus,
  SCRAPE_PRODUCT_DATA,
} from '@/utils/eventBus';
import ErrorsText from '@/constants/errors';
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'ProductCellUrlMultilineV2',
  mixins: [EditModeChildComponent],
  props: {
    isExpandedMode: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Object,
      default: null,
    },
    isOpenedInDialog: {
      type: Boolean,
      default: false,
    },
    openLinkInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isFocus: false,
      urlObj: {
        newValue: '',
      },
      textareaExpandedWidth: 0,
    };
  },
  computed: {
    ...mapState({
      isScrapingProductData: state => state.Collections.isFetching.scrapingProductData,
    }),
    ...mapGetters('Collections', ['isAddModalVariant']),
    urlsString() {
      return this.urlObj.newValue;
    },
    urlsArr() {
      const urls = this.urlsString?.split('\n') || [];
      return urls?.filter(url => url);
    },
    hintMessage() {
      if (!this.urlsString || this.urlErrorMessage.length) {
        return '';
      }
      return 'Press ENTER to add new link';
    },
    regexResultForUrl() {
      const { urlsString: val, isValidUrl, urlsArr: urls } = this;
      if (!val) {
        return true;
      }
      const isValid = urls.every(url => isValidUrl(url));
      return isValid;
    },
    urlErrorMessage() {
      if (this.regexResultForUrl) return [];
      return [ErrorsText.ERROR_VALID_URL];
    },
    showLinkOpenInNewTab() {
      return this.isOpenedInDialog
        && this.urlsArr.length
        && !this.urlErrorMessage.length
        && this.openLinkInNewTab;
    },
    valueForRendering() {
      const { value } = this;
      return Array.isArray(value) ? value[0] : value;
    },
    getColumnSize() {
      const size = this.header?.columnSize?.size || 180;
      return `${size - 20}px`;
    },
    showScrapeButton() {
      if (!this.isAddModalVariant) return false;
      if (this.header?.value !== productHeaders.WEBSITE_LINK) return false;
      return true;
    },
    isScraperDisabled() {
      if (this.isScrapingProductData) return true;
      if (!this.regexResultForUrl) return true;
      return this.urlsArr.length <= 0;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!Array.isArray(val)) {
          this.urlObj.newValue = '';
        } else {
          this.urlObj.newValue = val?.join('\n') || '';
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await waitTimeout(0);
    this.textareaExpandedWidth = this.$refs?.textareaRef?.$el?.offsetWidth || 0;
  },
  methods: {
    linkToOpening(url) {
      return prependHttp(url);
    },
    isValidUrl(val) {
      if (!val) return true;
      // eslint-disable-next-line
      const regexArr = val?.match(/^(?:(?:http|ftp)s?:\/\/)?(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,6}\.?|[a-zA-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/);
      return regexArr?.length;
    },
    update() {
      this.isFocus = false;
      if (!this.regexResultForUrl) {
        return;
      }
      this.$emit('change', {
        values: this.urlsArr || [],
      });
      this.toggleEditMode();
    },
    scrapeUrlForData() {
      EventBus.$emit(SCRAPE_PRODUCT_DATA, {
        url: this.valueForRendering,
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-text-field.v-textarea .v-text-field__slot textarea {
  overflow: auto !important;
}
.url-wrapper {
  word-break: break-all;
  line-clamp: unset !important;
}
.overflow-link {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.scraper {
  position: absolute;
  top: 0.5rem;
  right: 0.625rem;
  transition: filter 0.3s ease;
}
.scraper-enabled {
  &:hover {
    filter: brightness(1.4);
    cursor: pointer;
  }
}
.scraper-disabled {
  opacity: 0.6;
  filter: grayscale(0.8);

  &:hover {
    cursor: not-allowed;
  }
}
.textarea-scraper-padding {
  ::v-deep .v-input__slot {
    padding-right: 40px !important;
  }
}

// Used in table mode (cell pops out like in Sheets/Excel)
.editing-container {
  position: absolute;
  padding-top: 2.25rem;
  padding-bottom: 4px;

  // prevent the 'double-borders' in parent
  border: 1px solid var(--v-green-base);
  background: var(--v-lightGrey-base);
  top: -1px;
  left: -1px;
  transform: scale(1.01 );
  transform-origin: top left;
}
</style>
